import { type InputPresenter, type InputPresenterConfig } from '$ui/input'
import { bva, cn } from '$ui/utils'

export const standard = {
  checkbox: bva(`
    peer size-4 shrink-0 rounded-xs border border-primary shadow-sm text-primary
    focus:outline-hidden focus:ring-4 focus:ring-ring/30 focus:border-ring
    disabled:cursor-not-allowed disabled:opacity-50

    relative *:data-part-indicator:absolute *:data-part-indicator:inset-0 *:data-part-indicator:stroke-3
    *:data-part-indicator:flex *:data-part-indicator:items-center *:data-part-indicator:justify-center

    data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
  `, {}),

  radio(config: Partial<InputPresenterConfig>) {
    return cn(this.checkbox(config), 'rounded-full')
  },

  input: bva(`
    block w-full rounded-md text-foreground py-0
    border border-border shadow-sm px-3 text-start
    placeholder:text-muted
    disabled:text-silence disabled:bg-silence/5
    focus:outline-hidden focus:border-ring focus:ring-4 focus:ring-ring/30
    group-data-[state=invalid]:border-destructive group-data-[state=invalid]:ring-destructive/30
  `,
  {
    size: {
      sm: 'h-7 text-sm',
      default: 'h-9',
      lg: 'h-10',
    }
  }),
  select(config) { return this.input(config) }
} satisfies InputPresenter

declare module '$ui/input' {
  interface InputPresenters { }
}
