import * as AlertPresenters from './AlertPresenters'
import { provideAlertPresenters } from '$ui/alert'

import * as AlertDialogPresenters from './AlertDialogPresenters'
import { provideAlertDialogPresenters } from '$ui/alert-dialog'

import * as BadgePresenters from './BadgePresenters'
import { provideBadgePresenters } from '$ui/badge'

import * as CardPresenters from './CardPresenters'
import { provideCardPresenters } from '$ui/card'

import * as ButtonPresenters from './ButtonPresenters'
import { provideButtonPresenters } from '$ui/button'

import * as ButtonGroupPresenters from './ButtonGroupPresenters'
import { provideButtonGroupPresenters } from '$ui/button-group'

import * as ContainerPresenters from './ContainerPresenters'
import { provideContainerPresenters } from '$ui/container'

import * as DialogPresenters from './DialogPresenters'
import { provideDialogPresenters } from '$ui/dialog'

import * as DropdownMenuPresenters from './DropdownMenuPresenters'
import { provideDropdownMenuPresenters } from '$ui/dropdown-menu'

import * as NavPresenters from './NavPresenters'
import { provideNavPresenters } from '$ui/nav'

import * as InputPresenters from './InputPresenters'
import { provideInputPresenters } from '$ui/input'

import * as PaginationPresenters from './PaginationPresenters'
import { providePaginationPresenters } from '$ui/pagination'

import * as ProgressPresenters from './ProgressPresenters'
import { provideProgressPresenters } from '$ui/progress'

import * as FormPresenters from './FormPresenters'
import { provideFormPresenters } from '$ui/simple_form'

import * as TablePresenters from './TablePresenters'
import { provideTablePresenters } from '$ui/table'

import * as TabsPresenters from './TabPresenters'
import { provideTabsPresenters } from '$ui/tabs'

import * as TooltipPresenters from './TooltipPresenters'
import { provideTooltipPresenters } from '$ui/tooltip'

import * as WellPresenters from './WellPresenters'
import { provideWellPresenters } from '$ui/well'


export function install() {
  provideAlertPresenters(AlertPresenters)
  provideAlertDialogPresenters(AlertDialogPresenters)
  provideBadgePresenters(BadgePresenters)
  provideCardPresenters(CardPresenters)
  provideButtonPresenters(ButtonPresenters)
  provideButtonGroupPresenters(ButtonGroupPresenters)
  provideContainerPresenters(ContainerPresenters)
  provideDialogPresenters(DialogPresenters)
  provideDropdownMenuPresenters(DropdownMenuPresenters)
  provideNavPresenters(NavPresenters)
  providePaginationPresenters(PaginationPresenters)
  provideProgressPresenters(ProgressPresenters)
  provideFormPresenters(FormPresenters)
  provideTablePresenters(TablePresenters)
  provideTabsPresenters(TabsPresenters)
  provideTooltipPresenters(TooltipPresenters)
  provideWellPresenters(WellPresenters)
  provideInputPresenters(InputPresenters)
}
