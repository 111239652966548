import { type NavPresenter } from '$ui/nav'
import { bva } from '$ui/utils'

export const tabs = {
  list: bva('flex border-b gap-2', { }),
  item: bva(`
    rounded-t-md px-4 h-9 box-border -mb-px border border-transparent text-primary block cursor-pointer
    hover:text-foreground hover:border-border
    disabled:text-silence disabled:border-transparent
    data-[state=active]:text-foreground data-[state=active]:bg-card-background data-[state=active]:border-border
    data-[state=active]:border-b-transparent data-[state=active]:pb-px
  `, { }),
} satisfies NavPresenter


export const pill = {
  list: bva('flex gap-2', { }),
  item: bva(`
    rounded-md px-3 py-2 cursor-pointer
    text-gray-500 hover:text-gray-700
    data-[state=active]:bg-primary/10 data-[state=active]:text-primary
  `, {
    size: {
      xs: '',
      sm: '',
      default: '',
    }
  })
} satisfies NavPresenter


export const underline = {
  list: bva('-my-px flex gap-2', {}),
  item: bva(`
    inline-flex items-center text-foreground cursor-pointer
    border-b-2 px-3 pt-[2px] border-transparent
    hover:border-transparent
    data-[state=active]:border-primary data-[state=active]:text-foreground
  `, { }),
} satisfies NavPresenter


export const navbar = {
  list: bva('-my-px flex gap-1', {}),
  item: bva(`
    inline-flex items-center text-foreground cursor-pointer
    border-b-2 px-3 pt-[2px] border-transparent
    hover:border-transparent
    data-[state=active]:border-primary data-[state=active]:text-foreground
    [&>.icon]:me-3 [&>.icon]:-ms-0.5 [&>.icon]:text-silence
  `, { }),
} satisfies NavPresenter


declare module '$ui/nav' {
  interface NavPresenters {
    tabs: typeof tabs
    pill: typeof pill
    underline: typeof underline
    navbar: typeof navbar
  }
}
