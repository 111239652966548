import { type WellPresenter } from '$ui/well'
import { bva } from '$ui/utils'

export const standard = {
  root: bva(`border rounded-md inline-flex items-center p-2 shadow-xs`, {
    variant: {
      primary: 'bg-primary/15',
      destructive: 'bg-destructive/5',
      muted: 'bg-muted/5',
    },
  }),
} satisfies WellPresenter

declare module '$ui/well' {
  interface WellPresenters {
    standard: typeof standard
  }
}
