import { type CardPresenter } from '$ui/card'
import { bva } from '$ui/utils'

export const standard = {
  root: bva('rounded-md border bg-card-background text-card-foreground relative flex flex-col', {}),
  header: bva(`
    flex border-b p-4 relative items-center
    *:data-part-inner:flex
    *:data-part-actions:ms-auto *:data-part-actions:space-x-1
  `, {}),
  content: bva('p-4 grow', {}),
  table: bva(``, {}),
  footer: bva('flex border-t items-center p-4', {}),
  title: bva('font-semibold leading-none tracking-tight', {}),
  description: bva('text-sm text-muted', {}),
  topState: bva(`
    h-1 absolute top-0 w-full
    before:absolute before:size-full before:-translate-x-full before:bg-clip-content
    after:absolute after:size-full after:bg-clip-content
    rounded-md rounded-b-none
    bg-primary
  `, {}),
} satisfies CardPresenter

declare module '$ui/card' {
  interface CardPresenters {
  }
}
