import { type ContainerPresenter } from '$ui/container'
import { bva } from '$ui/utils'

export const fluid = {
  root: bva(`px-4`, {}),
} satisfies ContainerPresenter

export const fixed = {
  root: bva(`px-4 container mx-auto`, {}),
} satisfies ContainerPresenter

declare module '$ui/container' {
  interface ContainerPresenters {
    fluid: typeof fluid
    fixed: typeof fixed
  }
}
